// Global Nav Position Setting
document.addEventListener('DOMContentLoaded', function () {
    // Slick setting
    jQuery('.slick-carousel').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        fade: true,
        asNavFor: '.slick-nav'        
    });
    jQuery('.slick-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slick-carousel',
        // dots: true,
        centerMode: true,
        focusOnSelect: true
      });



    // Slickの再生・停止
    jQuery('.slick-stop').on('click', function () {
        jQuery('.slick-carousel')
            .slick('slickPause')
    });
    jQuery('.slick-play').on('click', function () {
        jQuery('.slick-carousel')
            .slick('slickPlay')
    });
    // const headerHeight = 200;
    // jQuery('a[href^="#"]' + 'a:not(.tab-link,.blockskip .show-on-focus)').click(function () {
    //     const href = jQuery(this).attr("href");
    //     const target = jQuery(href == "#" || href == "" ? 'html' : href);
    //     const position = target.offset().top - headerHeight;
    //     jQuery("html, body").animate({
    //         scrollTop: position
    //     }, 200, "swing");
    //     // 移動先にfocusを当てる
    //     target.focus();
    //     if (target.is(":focus")) {
    //         return false;
    //     } else {
    //         target.attr('tabindex', '-1');
    //         target.focus();
    //     };
    //     return false;
    // });

   

});