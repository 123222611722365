// //スクロールをするたびにアニメーションを行う設定  
jQuery('.bubble-l').on('inview', function(event, isInView) {
    if (isInView) {//表示領域に入った時
      $(this).addClass('animate__animated animate__shakeX');//クラス名が付与
    } else {//表示領域から出た時
      $(this).removeClass('animate__animated animate__shakeX');//クラス名が除去
    }
  });
jQuery('.bubble-r').on('inview', function(event, isInView) {
    if (isInView) {//表示領域に入った時
      $(this).addClass('animate__animated animate__shakeY');//クラス名が付与
    } else {//表示領域から出た時
      $(this).removeClass('animate__animated animate__shakeY');//クラス名が除去
    }
  });

//スクロールをしたら1度だけアニメーションをする設定
  jQuery('.animate-l2r').on('inview', function(event, isInView) {
    if (isInView) {//表示領域に入った時
		jQuery(this).addClass('animate__bounceInLeft animate__animated');//クラス名が付与
    }
  });
  jQuery('.animate-r2l').on('inview', function(event, isInView) {
    if (isInView) {//表示領域に入った時
		jQuery(this).addClass('animate__bounceInRight animate__animated');//クラス名が付与
    }
  });
  
  jQuery('.animate-slideUp').on('inview', function(event, isInView) {
    if (isInView) {//表示領域に入った時
		jQuery(this).addClass('animate__fadeInUpBig animate__animated');//クラス名が付与
    }
  });


// //違う動きを追加設定  
// $('.fadeInDownTrigger').on('inview', function(event, isInView) {
//     if (isInView) {//表示領域に入った時
//       $(this).addClass('animate__animated');//クラス名が付与
//     } else {//表示領域から出た時
//       $(this).removeClass('animate__animated');//クラス名が除去
//     }
//   });