document.addEventListener('DOMContentLoaded', function () {
  jQuery('.search')
    .bind('click', function(event) {
      jQuery(".search-field").toggleClass("expand-search");

      // if the search field is expanded, focus on it
      if (jQuery(".search-field").hasClass("expand-search")) {
        jQuery(".search-field").focus();
      }
    })
});

